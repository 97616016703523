<template>
  <!-- <div class="columns is-centered">
    <div class="column is-full logo-container">
      <img src="@/assets/helpharma.png" alt="helpharma logo" />
    </div>
  </div> -->
  <div class="container">
    <div class="columns vertical-bottom mt-1">
      <div class="column is-4">
        <div
          class="temporal-box temporal-survey-card"
          v-if="temporalSurvey.module"
        >
          <div class="columns is-mobile pr-4">
            <div
              class="column is-11 temporal-survey-data"
              @click="
                $router.push({
                  name: temporalSurvey.module.route,
                  params: { temporalData: temporalSurvey.survey },
                })
              "
            >
              <a>Continuar encuesta... </a>
              <a>{{ temporalSurvey.module.name }}</a>
            </div>
            <div
              class="column is-2 close-container pl-1"
              @click="deleteTemporalSurvey(temporalSurvey.module.name)"
            >
              <div class="close-box pl-2 pt-2">
                <b-icon
                  class="temporal-close-button"
                  size="is-medium"
                  icon="close-circle"
                ></b-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12 main">
        <!-- <span class="heading">Recent Orders</span> -->
        <section id="order">
          <!-- <div class="order-body">
            <article class="media order shadow delivered">
              <figure class="media-left">
                <i class="fa fas-box"></i>
              </figure>
              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>Total clinimetrias por sincronizar.</strong>
                    <br />
                    <small
                      >Son aquellas que por algún motivo hi-med no pudo llevar
                      el resultado al historial clínico
                      <strong>15</strong>
                    </small>
                  </p>
                </div>
              </div>
              <div class="media-right">
                <div class="tags has-addons">
                  <span class="tag is-light">Estado:</span>
                  <span class="tag is-delivered">Pendiente</span>
                </div>
              </div>
            </article>
            <article class="media order shadow">
              <figure class="media-left">
                <i class="fas fa-box"></i>
              </figure>
              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>Total de clinimetrias reportadas</strong>
                    <br />
                    <small
                      >Son aquellas que has sido enviadas de forma satisfactoria
                      al sistema hi-med
                      <strong>2157</strong>
                    </small>
                  </p>
                </div>
              </div>
              <div class="media-right">
                <div class="tags has-addons">
                  <span class="tag is-light">Estado:</span>
                  <span class="tag is-success">Realizadas</span>
                </div>
              </div>
            </article>
          </div> -->
        </section>
        <span class="heading">Clinimetrias</span>
        <section class="info-tiles">
          <div class="tile is-ancestor has-text-centered">
            <div class="tile is-parent">
              <article class="tile is-child box">
                <p class="title">{{ suerveysCount }}</p>
                <p class="subtitle">Total clinimetrias</p>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child box">
                <p class="title">{{ surveysByDoctorCount }}</p>
                <p class="subtitle">Clinimetrias del médico</p>
              </article>
            </div>
            <!-- <div class="tile is-parent">
              <article class="tile is-child box">
                <p class="title">24</p>
                <p class="subtitle">Open Orders</p>
              </article>
            </div> -->
          </div>
        </section>
        <div class="columns"></div>
      </div>
    </div>
    <br />
    <br />
    <div class="columns">
      <div class="column">
        <p class="subtitle">Total clinimetrias realizadas en el año en curso</p>
        <div class="box">
          <apexchart
            type="bar"
            width="100%"
            :options="chartOptions"
            :series="series"
            v-if="yearLoaded"
          ></apexchart>
        </div>
      </div>
      <div class="column">
        <p class="subtitle">Clinimetrias más gestionadas</p>
        <div class="box">
          <apexchart
            width="100%"
            type="polarArea"
            :options="options"
            :series="series2"
            v-if="loaded"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      temporalSurvey: {},
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            // click: function(chart, w, e) {
            //   console.log(chart, w, e);
            // },
          },
        },
        colors: [],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: [],
              fontSize: "12px",
            },
          },
        },
      },
      options: {
        chart: {
          type: "polarArea",
        },
        colors: [],
        labels: [],
        stroke: {
          colors: ["#fff"],
        },
        fill: {
          opacity: 0.8,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      series2: [],
      series: [
        {
          name: "Encuestas en el mes",
          data: [],
        },
      ],
      loaded: false,
      yearLoaded: false,
      suerveysCount: 0,
      surveysByDoctorCount: 0,
    };
  },
  async created() {
    if (this.$session.has("temporalSurvey")) {
      this.temporalSurvey = this.$session.get("temporalSurvey");
    }

    this.getInitialLoad();
  },
  computed: {
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
  },
  methods: {
    deleteTemporalSurvey(message) {
      this.temporalData = this.temporalSurvey;
      this.temporalSurvey = {};
      this.$buefy.snackbar.open({
        duration: 7000,
        message: `Se borrarán los datos de la encuesta <strong class="toast-user-welcome"> ${message} </strong> `,
        type: "is-danger",
        position: "is-bottom-left",
        actionText: "Deshacer",
        cancelText: "Aceptar",
        onAction: () => {
          this.restoreData();
        },
      });
      this.clearTemporalSurveys();
    },
    restoreData() {
      this.$session.set("temporalSurvey", this.temporalData);
      this.temporalSurvey = this.temporalData;
    },
    async getSurveysCount() {
      this.changeLoaderStatus(true);
      const response = await this.$clinimetriasService.getSurveysCount();
      this.suerveysCount = response.data;
    },
    async getSuerveysMadeByDoctor() {
      const response = await this.$surveyService.getCountByDoctor(this.userId);
      this.surveysByDoctorCount = response.data;
    },
    async getMostCompleted() {
      this.loaded = false;
      const response = await this.$surveyService.getMostCompletedEvaluations();

      response.data.forEach((element) => {
        this.options.labels.push(element.codigo_encuesta);
        this.series2.push(element.numero_encuestas);
        this.options.colors.push(this.newColor());
      });
      this.loaded = true;
    },
    async surveysGoupedByMonth() {
      this.yearLoaded = false;
      const response = await this.$surveyService.getGroupedByMonth();

      response.data.forEach((element) => {
        this.chartOptions.xaxis.categories.push(element.mes);
        this.series[0].data.push(element.numero_encuestas);
        this.chartOptions.colors.push(this.newColor());
      });
      this.yearLoaded = true;
    },
    async getInitialLoad() {
      try {
        this.changeLoaderStatus(true);
        await this.getSurveysCount();
        await this.getSuerveysMadeByDoctor();
        await this.getMostCompleted();
        await this.surveysGoupedByMonth();
      } catch (error) {
        console.log(error);
      } finally {
        this.changeLoaderStatus(false);
      }
    },
    newColor() {
      let symbols = "0123456789ABCDEF";
      let color = "#";

      for (let index = 0; index < 6; index++) {
        color = color + symbols[Math.floor(Math.random() * 16)];
      }

      return color;
    },
  },
};
</script>

<style scoped>
/* .logo-container {
  text-align: center;
  height: 74vh;
}

.logo-container img {
  position: relative;
  top: 50%;
  width: 30%;
}

@media screen and (max-width: 768px) {
  .logo-container img {
    width: 100%;
  }
} */
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans|Open+Sans");
.columns {
  width: 100%;
  height: 100%;
}

.shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.main .heading {
  padding: 20px 0px;
  font-weight: 600;
  font-size: 1.1rem;
  text-transform: none;
}
.main #order {
  color: #ffffff;
}
.main #order strong {
  color: #ffffff;
}
.main #order article {
  padding: 12px;
  background-color: var(--medium-blue-color);
  border-radius: 5px;
}
.main #order article.delivered {
  background: #13c268;
  border-radius: 5px;
}
.main #order .media-left {
  font-size: 2rem;
}
.main #order .media-right {
  line-height: 48px;
  padding-top: 11px;
}
.main #order .tag:not(body).is-success {
  background-color: #53a5e2;
}
.main #order .tag:not(body).is-delivered {
  background-color: #0e944f;
  color: white;
}

.tile .is-child {
  background: linear-gradient(
      155deg,
      rgba(32, 45, 61, 0.8) 0%,
      rgba(56, 75, 99, 0.8) 100%
    ),
    url(http://cdn.backgroundhost.com/backgrounds/subtlepatterns/vaio_hard_edge.png);
  background-size: cover;
}
.tile .is-child .title,
.tile .is-child .subtitle {
  color: white;
}

.temporal-survey-card {
  cursor: pointer;
  background-color: var(--gray-color);
}

.temporal-survey-card:hover .temporal-close-button {
  color: var(--dark-red-color);
}

.temporal-survey-data {
  display: grid;
}

.close-container:hover .close-box {
  background-color: var(--gray-color);
  border-radius: 50%;
}

.close-box {
  height: 3rem;
  width: 3rem;
}

.vertical-bottom {
  align-items: flex-end;
}
.temporal-box {
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  padding: 1.25rem;
  border-radius: 6px;
}

.temporal-box a {
  color: var(--dark-blue-color);
  font-weight: bold;
}

.temporal-box:hover a {
  color: var(--sky-blue-color);
}

.temporal-box:hover {
  background-color: var(--dark-blue-color);
}

/* Made by: Eric Kelley */
/* https://codepen.io/emkelley/pen/pVrGxX */
</style>
